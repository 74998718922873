/*.product-grid {*/
/*    display: grid; !* Sử dụng Grid *!*/
/*    !*grid-template-columns: repeat(3, 1fr); !* 3 cột cho desktop *!*!*/
/*    gap: 8px; !* Khoảng cách giữa các sản phẩm *!*/
/*    width: 100%; !* Đảm bảo chiều rộng của grid là 100% *!*/
/*}*/


/*.product-item {*/
/*    border: 1px solid #ccc;*/
/*    padding: 8px;*/
/*    text-align: start;*/
/*    box-sizing: border-box;*/
/*    gap: 10px;*/
/*}*/

/*.product-image {*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/

.product-options {
    display: flex;
    flex-wrap: wrap; /* Cho phép các phần tử con quấn xuống hàng khi không đủ chỗ */
    gap: 10px; /* Khoảng cách giữa các thẻ select */
}

.product-options select {
    flex: 1; /* Đảm bảo các thẻ <select> có cùng kích thước và chiếm hết chiều rộng của container */
    min-width: 200px; /* Đặt chiều rộng tối thiểu để đảm bảo không bị quá nhỏ */
    padding: 8px 12px; /* Thêm padding cho thẻ <select> */
    font-size: 16px; /* Đặt kích thước chữ cho dễ đọc hơn */
    border-radius: 4px; /* Tạo góc bo tròn cho thẻ <select> */
    border: 1px solid #ccc; /* Đặt viền cho thẻ <select> */
}

.product-buttons {
    display: flex;
    margin-top: 8px;
    height: 35px;
    border: none;
}

.product-buttons button {
    cursor: pointer;
    font-size: 16px;
    border: none;
    border-radius: 4px;
}

.select-attribute {
    flex-direction: column;
    gap: 8px;
    display: flex;
}

.btn-buy {
    background: #ee4d2d;
    color: white;
    flex-grow: 1;
    margin-left: 6px;
}

.product-buttons .card {
    margin-left: 4px;
    font-size: 14px;
}

.cartImg {
    width: 18px;
    height: 18px;
}

.btn-cart {
    align-items: center;
    font-size: 12px;
    background: rgba(238, 77, 45, 0.4);
    display: flex;
    padding: 10px;
    border-radius: 4px;
}

h3, h5 {
    padding: 0;
    margin: 0;
}

.bangSize {
    width: 100%;
    height: 100%;
    margin-top: 10px;
}

.catalog {
    margin-bottom: 20px;
    width: 100%;
    display: block;
}

.catalog-title {
    padding: 8px;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.catalog-product-item {
    border: 1px solid #ddd;
    padding: 8px;
    background-color: #fff;
    gap: 4px;
}


@media (min-width: 768px) {
    .product-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}


