/* Banner container bao gồm ảnh và số điện thoại */
.banner-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.banner-image {
    width: 100%;
    height: 100%;
    display: block;
}

.phone-number {
    left: 50%;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
