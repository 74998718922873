h4 {
    margin: 4px 0 4px 0;
    padding: 0;
}

.circle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
}

.color-item {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: row; /* Sắp xếp theo chiều ngang */
    margin: 2px 4px 2px 0; /* Khoảng cách giữa các item */
    border: 1px solid #a6a6a6; /* Thêm border */
    border-radius: 20px; /* Bo góc cho border để tạo hình chip */
    padding: 2px 8px 2px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Thêm bóng đổ cho chip */
}