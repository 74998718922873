.detail-product-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}

.breadcrumb {
    margin-bottom: 20px;
}

.breadcrumb a {
    text-decoration: none;
    color: #000;
}

.product-detail {
    display: flex;
    gap: 40px;
}

.product-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.thumbnail-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.thumbnail-images img {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    object-fit: cover;
}

.main-image img {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
}


.product-info h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.price {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.in-stock {
    color: green;
    font-weight: bold;
}

.quantity-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.quantity-decrease,
.quantity-increase {
    width: 30px;
    height: 30px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
}

.quantity-decrease:hover,
.quantity-increase:hover {
    background-color: #e0e0e0;
}

input[type="number"] {
    width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    padding: 5px;
}

.action-buttons {
    display: flex;
    gap: 20px;
}

.add-to-cart {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
}

.buy-now {
    padding: 10px 20px;
    background-color: red;
    color: #fff;
    border: none;
    cursor: pointer;
}

.add-to-cart:hover,
.buy-now:hover {
    opacity: 0.8;
}


.tags {
    margin-top: 10px; /* Khoảng cách giữa mô tả và tag */
}

.tag {
    width: 100%;
    display: inline-block; /* Hiển thị các tag trong dòng */
    background-color: #edeaea; /* Màu nền cho tag */
    padding: 5px 10px; /* Padding cho tag */
    margin-right: 5px; /* Khoảng cách giữa các tag */
    border-radius: 3px; /* Bo tròn cho góc tag */
    font-size: 14px; /* Kích thước chữ cho tag */
}

