.product-image-container {
    position: relative;
    display: inline-block;
}

.product-image {
    display: block;
    object-fit: contain;
    width: 100%;
    max-height: 400px;
}

.discount-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: red;
    color: white;
    font-size: 12px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
}
