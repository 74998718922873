/* Đảm bảo toàn bộ trang chiếm 100% chiều cao của cửa sổ */
.page-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Chiều cao toàn trang */
    overflow: hidden; /* Ẩn overflow ở mức toàn trang */
}

/* Header sẽ được cố định ở đầu trang */
.page-container > Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; /* Đảm bảo Header ở trên cùng */
    background-color: white; /* Đảm bảo Header có nền nếu cần */
}

/* Nội dung có thể cuộn (bao gồm Banner và main-content) */
.scrollable-content {
    margin-top: 60px; /* Đặt dưới Header (chiều cao của Header) */
    overflow-y: auto; /* Cho phép cuộn khi cần */
    flex-grow: 1; /* Để chiếm hết phần còn lại của trang */
}

