.popup-overlay {
    padding: 12px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    font-size: 16px;
    color: rgba(241, 81, 81, 0.91);
    font-weight: bold;
}

.close-icon:hover {
    color: #000; /* Màu khi hover */
}


.close-btn, .confirm-btn {
    padding: 10px 20px;
    cursor: pointer;
}

.close-btn {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
}

.confirm-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
}

.select-style {
    display: flex;
}
