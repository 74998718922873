.quantity-selector {
    margin-top: 8px;
    display: flex;
    align-items: center; /* Căn giữa các phần tử */
    gap: 10px; /* Khoảng cách giữa các phần tử */
}

button {
    /*padding: 0px 4px; !* Padding cho button *!*/
    font-size: 14px; /* Kích thước chữ cho button */
    cursor: pointer; /* Con trỏ chuột khi hover */
}

button:disabled {
    cursor: not-allowed; /* Con trỏ không cho phép khi button bị vô hiệu hóa */
    opacity: 0.5; /* Giảm độ sáng của button khi bị vô hiệu hóa */
}
