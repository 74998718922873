.main-checkout {
    position: relative;
    display: flex;
    flex-direction: column;
}

.checkout-container {
    display: flex;
    flex-direction: column;
    flex: 1; /* Cho phép thẻ này chiếm không gian còn lại */
    overflow-y: auto; /* Cho phép cuộn dọc */
    padding: 16px; /* Thêm padding nếu cần */
    background-color: white; /* Màu nền cho nội dung */
    gap: 10px;
}


.btn-checkout {
    width: 50%;
    height: 40px;
    background-color: #e65454;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.footer-checkout {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.product-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
    background: aliceblue;
}

.image-cart {
    width: 60px;
    height: 60px; /* Chiều cao ảnh */
    object-fit: cover; /* Giữ tỉ lệ và cắt ảnh nếu cần */
    margin-right: 10px; /* Khoảng cách giữa ảnh và chi tiết sản phẩm */
}

.product-info {
    display: flex;
    flex-direction: row; /* Để hiển thị thông tin sản phẩm theo cột */
    position: relative; /* Đặt vị trí tương đối cho .product-info */
}


.btn-clear {
    position: absolute; /* Đặt "Hello" ở vị trí tuyệt đối */
    top: 0;
    margin: 0;
    right: 0; /* Khoảng cách từ bên phải */
    z-index: 1; /* Đặt z-index cao hơn để nó nằm trên các phần tử khác */
    background-color: lightgray; /* Nền trắng để dễ nhìn thấy */
    color: gray;
    padding: 8px;
    font-size: 12px;
    border-radius: 4px;
}


.product-details {
    display: flex;
    flex-direction: column; /* Để hiển thị thông tin sản phẩm theo cột */
    margin-top: 8px;
}

.product-name {
    font-size: 18px;
    font-weight: bold;
}

.product-price,
.product-color,
.product-size {
    font-size: 14px;
    margin: 5px 0; /* Khoảng cách giữa các thông tin */
}



