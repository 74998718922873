.header {
    display: flex;
    align-items: center; /* Căn giữa theo chiều dọc */
    justify-content: space-between; /* Căn giữa các phần tử */
    padding: 10px;
    background: red;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Đổ bóng nhẹ */
    position: fixed; /* Cố định header */
    top: 0; /* Đặt header ở trên cùng */
    left: 0; /* Đặt header ở bên trái */
    right: 0; /* Đặt header ở bên phải */
}

.image-logo {
    width: 60px;
    height: auto;
}

.search-input {
    flex: 1; /* Để input chiếm không gian còn lại */
    margin: 0 10px; /* Khoảng cách hai bên */
    padding: 10px; /* Khoảng cách bên trong */
    border: 1px solid #ccc; /* Viền */
    border-radius: 4px; /* Bo góc */
}

.cart-icon {
    position: relative; /* Để vị trí cho số lượng */
    display: flex;
    align-items: center; /* Căn giữa theo chiều dọc */
    right: 4px;
}

.cart-image {
    width: 24px; /* Kích thước icon giỏ hàng */
    height: 24px; /* Kích thước icon giỏ hàng */
}

.cart-count {
    position: absolute; /* Để đặt số lượng lên trên icon */
    top: -5px; /* Di chuyển lên trên */
    right: -10px; /* Di chuyển sang bên phải */
    background-color: red; /* Màu nền đỏ */
    color: white; /* Màu chữ trắng */
    border-radius: 50%; /* Bo góc thành hình tròn */
    padding: 2px 6px; /* Khoảng cách bên trong */
    font-size: 12px; /* Kích thước chữ */
}
