body {
    font-family: Arial, sans-serif;
}

.main-content {
    padding: 8px;
}

input, select {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

* {
    box-sizing: border-box; /* Áp dụng cho tất cả phần tử */
}
